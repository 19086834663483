<template>
  <div
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <img :src="require('@/assets/images/pages/reject-logo.png')">
      <div class="greetings">
        Mohon maaf, saat ini Anda belum bisa mengikuti E-Vote
      </div>
      <div class="info">
        {{ msg }}
      </div>
    </div>
    <ButtonLogoutVue />
  </div>
</template>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 28px;
  }

  .mobile-size img {
    height: 160px;
    width: 160px;
    margin-bottom: 36px;
  }

  .mobile-size .greetings {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #1B1B1B;
  }

  .mobile-size .greetings span {
    color: #4FBBEA;
  }

   .mobile-size .info {
     margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* primary */

    color: #1B1B1B;

   }

   .mobile-size .info span {
      color: #4FBBEA;
    }
</style>

<script>
import API from '@/api'
import ButtonLogoutVue from '@/components/ButtonLogout/ButtonLogout.vue'

export default {
  components: {
    ButtonLogoutVue,
  },
  data() {
    return {
      msg: '',
    }
  },
  computed: {
  },
  async beforeRouteEnter(_to, _from, next) {
    try {
      await API.evote.validateVoter()
      return next('/')
    } catch (error) {
      if (error.response.status === 422) {
        return next(vm => vm.setMsg(error.response.data.message))
      }
      return next(vm => vm.setMsg(error.response.data.message))
    }
  },
  methods: {
    setMsg(msg) {
      this.msg = msg
    },
  },
}
</script>
